export const budgetPlanFormTexts = {
    travel: {
        label: 'Fahrt',
        info:
            'Fahrt bzw. Flug ins Gastland und zurück sowie weitere Ausgaben z. B. für Transfers',
    },
    residence: {
        label: 'Aufenthalt',
        info: 'z.B. Unterkunft, Verpflegung, Transportmittel, Freizeit',
    },
    organisation: {
        label: 'Organisationsmittel',
    },
    processing: {
        label: 'Vor- und Nachbereitung',
        info: 'z.B. Sprachkurs, interkultureller Vorbereitungskurs, Bücher',
    },
    disabledReqs: {
        label: 'Zusätzlicher Bedarf für Menschen mit Behinderungen (in EUR)',
    },
    infoTextBlock: [
        {
            type: 'p',
            text:
                'Die Förderung wird als [b]anteilige Bezuschussung[b] und nicht als Vollfinanzierung vergeben. Geben'+
                'Sie hier an, welche Ausgaben Ihrer Einschätzung nach bei der Vorbereitung, Durchführung und Nachbereitung'+
                'aller Auslandsaufenthalte in diesem Projekt insgesamt entstehen werden. Es geht um eine Schätzung der '+
                'Gesamtausgaben, aufgeteilt nach den förderfähigen Kategorien und unabhängig davon, wer sich daran '+
                'beteiligen wird (Betrieb, Auszubildende, AusbildungWeltweit usw.).',
        },
    ],
    sumTotal: 'Summe der geplanten Ausgaben (gesamt):',
    warningIfLessThenFiscalYearSum:
        'Die geplanten Gesamtausgaben sind niedriger als der berechnete Zuschuss. Sind Sie sicher, dass Sie alle Ausgaben berücksichtigt haben?',
};
