export const explanationsFormTexts = {
    otherwiseFinanced: {
        label: 'Anderweitige öffentliche Finanzierung',
        values: {
            no:
                'Das Vorhaben ist oder wird nicht anderweitig mit Zuwendung oder Auftrag öffentlich finanziert.',
            yes:
                'Das Vorhaben ist oder wird anderweitig mit Zuwendung oder Auftrag öffentlich finanziert:',
        },
    },
    financedAmount: 'Höhe der Zuwendung',
    financedBy: 'Träger',
    furtherSpending: {
        title:
            'Werden durch das Vorhaben weitere Folgeausgaben für den Mittelgeber entstehen?',
        subTitle:
            '(Vorhaben, bei denen Folgeausgaben entstehen, sind nicht förderungswürdig.)',
        no: 'nein',
        yes: 'ja',
    },
    notStarted:
        'Hiermit erkläre ich, dass mit der Maßnahme noch nicht begonnen worden ist. Ich nehme gleichzeitig zur Kenntnis, '+
        'dass Ausgaben erst nach einer Bewilligung getätigt werden dürfen. ',
    noticed:
        'Ich habe zur Kenntnis genommen, dass die Nationale Agentur beim BIBB falls erforderlich zusätzliche Unterlagen für die Feststellung der Bonität anfordern kann.',
    resourcesPresent:
        'Ich verfüge über die entsprechenden Ressourcen, um das Projekt bei Gewährung der Förderung konform den vorgegebenen Qualitätskriterien durchzuführen.',
    academics: 'Forschungseinrichtung und Hochschulen',
    academicsFootnote:
        'Ist nur auszufüllen, wenn es sich beim Antragsteller um eine Forschungseinrichtung oder eine Hochschule handelt.',
    isForAcademics: {
        label: 'Dieser Antrag wird für den',
        nonEconomic: 'nicht wirtschaftlichen Bereich gestellt',
        economic: 'wirtschaftlichen Bereich gestellt',
        notApplicable: '(trifft nicht auf den Antragsteller zu)',
    },
    academicsPublic: 'Gilt nur für staatliche Hochschulen:',
    basicFinance: 'Grundfinanzierung',
    basicFinanceCheckbox:
        'Das Land beteiligt sich an den vorhabenbezogenen Gesamtausgaben durch die Finanzierung der Grundausstattung.',
    ressort:
        'Gilt nur für staatl. Hochschulen, wenn nach Landesrecht eine ausdrückliche schriftliche Ressortbestätigung nicht erforderlich ist.',
    ressortCheckbox:
        'Über den Antrag besteht Einvernehmen mit dem zuständigen Landesressort.',
    dataPrivacy: 'Rechtliche Hinweise und Datenschutz',
    dataPrivacyText: [
        {
            type: 'p',
            text:
                'Die im Antrag aufgeführten personenbezogenen Daten und sonstige Angaben werden von der NA beim BIBB im ' +
                'Rahmen ihrer Zuständigkeit erhoben, verarbeitet und genutzt ' +
                '[a|/datenschutz](zur Datenschutzerklärung)[a]. ' +
                'Eine Weitergabe dieser Daten an andere Stellen richtet sich nach dem Bundesdatenschutzgesetz (BDSG) bzw. ' +
                'nach den vorgehenden Rechtsvorschriften (§1 Abs. 2 BDSG). Der Antragsteller stimmt der Weitergabe der Daten ' +
                'zum Zweck der Evaluation des Programms AusbildungWeltweit zu.',
        },
    ],
    obligation: [
        {
            type: 'checkBoxLabel',
            text:
                'Mit der Unterzeichnung des Antrags verpflichtet sich der Antragsteller die ' +
                '[a|https://www.ausbildung-weltweit.de/dateien/Qualitaetsstandards_AusbildungWeltweit.pdf]Qualitätsstandards für ' +
                'Mobilitätsprojekte in der Berufsbildung[a] einzuhalten und die Reisewarnungen des Auswärtigen Amts zu beachten.',
        },
    ],
    informed:
        'Soweit im Antrag personenbezogene Daten von Beschäftigten des Antragstellers oder sonstigen natürlichen ' +
        'Personen aufgeführt werden, wurden diese entsprechend der Datenschutzhinweise informiert und ihr Einverständnis wurde eingeholt.',
    dataPolicy:
        'Der Antragsteller stimmt der Weitergabe der Daten zum Zweck der Antragsprüfung sowie der Evaluierung des ' +
        'Programms AusbildungWeltweit durch Sachverständige / Gutachterinnen und Gutachter zu.',
    correctness:
        'Die Richtigkeit und Vollständigkeit der Angaben wird versichert.',
};
