import { validations } from '../../util/Validation/validations';
import { registerFormTexts } from '../../texts/Forms/RegisterFormTexts';

export const registerFormConfig = {
    email: {
        label: 'E-Mail',
        name: 'email',
        validation: validations.email,
    },
    emailConfirm: {
        label: 'E-Mail bestätigen',
        name: 'emailConfirm',
        validation: (get) => ({
            required: 'Bitte geben Sie eine E-Mail an',
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Bitte geben Sie eine gütlige E-Mail Adresse an',
            },
            validate: (value) =>
                value === get(registerFormConfig.email.name) ||
                'Die E-Mail Adressen stimmen nicht überein',
        }),
    },
    password: {
        label: 'Passwort',
        name: 'password',
        validation: validations.password,
    },
    passwordConfirm: {
        label: 'Passwort bestätigen',
        name: 'passwordConfirm',
        validation: (get) => ({
            required: 'Bitte bestätigen Sie Ihr Passwort',
            validate: (value) =>
                value === get(registerFormConfig.password.name) ||
                'Die Passwörter stimmen nicht überein',
        }),
    },
    salutation: {
        label: 'Anrede',
        name: 'salutation',
        validation: validations.salutation,
        options: [
            { label: 'Herr', value: 'm' },
            { label: 'Frau', value: 'f' },
            { label: 'ohne Anrede', value: 'd' },
        ],
    },
    title: {
        label: 'Titel (optional)',
        name: 'title',
    },
    firstName: {
        label: 'Vorname',
        name: 'firstname',
        validation: validations.firstName,
    },
    lastName: {
        label: 'Nachname',
        name: 'lastname',
        validation: validations.lastName,
    },
    companyName: {
        label: 'Rechtsverbindlicher Name der Organisation',
        name: 'companyName',
        validation: validations.companyName,
    },
    street: {
        label: 'Straße und Hausnummer',
        name: 'street',
        validation: validations.street,
    },
    postcode: {
        label: 'PLZ',
        name: 'postcode',
        validation: validations.postCode,
    },
    city: {
        label: 'Ort',
        name: 'city',
        validation: validations.city,
    },
    state: {
        label: 'Bundesland',
        name: 'state',
        validation: validations.state,
    },
    phone: {
        label: 'Telefonnummer',
        name: 'phone',
        validation: validations.phone,
        helperText: registerFormTexts.phoneFormat,
    },
    consent: {
        label:
            'Ich habe die Einwilligungserklärung zur Verarbeitung meiner Daten zur Kenntnis genommen.',
        validation: {
            required: 'Bitte bestätigen Sie die Einwilligungserklärung.',
        },
    },
};
