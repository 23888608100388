import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { mobilityFormTexts as mft } from '../../../../texts/Forms/MobilityFormTexts';
import TableCell from '@material-ui/core/TableCell';
import { useMobilityListStyles } from '../../../../styles/components/forms/MobilityForm/MobilityListStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MobilityListEmpty from './MobilityListEmpty';
import MobilityListRow from './MobilityListRow';
import MobilityListLoading from './MobilityListLoading';
import MobilityListBody from './MobilityListBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dinero from 'dinero.js';

const MobilityList = (props) => {
    const {
        onCreate,
        data,
        isEditing,
        isFetching,
        isLoading,
        onDelete,
        onEdit,
        isDeleting,
        getError,
    } = props;
    const classes = useMobilityListStyles();

    const handleDelete = (index) => {
        onDelete(index);
    };

    const handleEdit = (index) => {
        onEdit(index);
    };

    const handleCreateNew = () => {
        onCreate();
    };

    const isDisabled = () => {
        return isDeleting || isEditing || isLoading;
    };

    const sum = React.useMemo(() => {
        return data
            .reduce((prev, cur) => {
                return prev.add(Dinero({ amount: parseInt(cur.total) }));
            }, Dinero({ amount: 0 }))
            .toFormat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, data.length]);

    return (
        <TableContainer className={classes.table}>
            <Table
                stickyHeader
                aria-rowcount={data.length}
            >
                <TableHead>
                    <TableRow>
                        <TableCell id="actionsId">
                            <Typography>
                                {mft.list.header.actions}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.maxWidth}>
                            {mft.list.header.destination}
                        </TableCell>
                        <TableCell className={classes.maxWidth}>
                            {mft.list.header.type}
                        </TableCell>
                        <TableCell className={classes.maxWidth}>
                            {mft.list.header.attendees}
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                            {mft.list.header.start}
                        </TableCell>
                        <TableCell className={classes.noWrap}>
                            {mft.list.header.end}
                        </TableCell>
                        <TableCell className={classes.maxWidth}>
                            {mft.list.header.total}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <MobilityListBody getError={getError}>
                    {isLoading ? (
                        <MobilityListLoading />
                    ) : data.length === 0 ? (
                        <MobilityListEmpty
                            isFetching={isFetching}
                            isLoading={isLoading}
                        />
                    ) : (
                        data.map((d, id) => (
                            <MobilityListRow
                                data={d}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                                index={id}
                                disabled={isDisabled()}
                                key={d['@id']}
                            />
                        ))
                    )}

                    <TableRow>
                        <TableCell colSpan={6} aria-colspan={6}>
                        </TableCell>
                        <TableCell>{sum}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell headers="actionsId" colSpan={7} aria-colspan={7}>
                            <div
                                style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    onClick={handleCreateNew}
                                    variant="contained"
                                    color={'primary'}
                                    disableElevation
                                    disabled={isDisabled()}
                                >
                                    {mft.list.empty.button}
                                </Button>
                                {isDeleting ? (
                                    <div>
                                        <CircularProgress
                                            size="1rem"
                                            style={{ marginLeft: 16 }}
                                        />
                                        <Typography
                                            component="span"
                                            variant="subtitle2"
                                            style={{ marginLeft: 4 }}
                                        >
                                            Wird gelöscht...
                                        </Typography>
                                    </div>
                                ) : null}
                            </div>
                        </TableCell>
                    </TableRow>
                </MobilityListBody>
            </Table>
        </TableContainer>
    );
};

MobilityList.propTypes = {
    isLoading: PropTypes.bool,
    isFetching: PropTypes.bool,
    data: PropTypes.array,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onCreate: PropTypes.func,
    isEditing: PropTypes.bool,
    isDeleting: PropTypes.bool,
    getError: PropTypes.object,
};

MobilityList.defaultProps = {
    data: [],
};

export default MobilityList;
