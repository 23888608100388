import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Controller } from 'react-hook-form';
import MoneyInput from './MoneyInput';
import {
    parseCentToString,
    parseToCentString,
} from '../../../util/Mutators/StringConversion';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InfoBalloon from './InfoBalloon';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    margin: {
        marginBottom: '8px',
        marginTop: '8px',
    },
}));

const RHFMoneyInput = (props) => {
    const {
        control,
        error,
        validation,
        name,
        label,
        info,
        clearErrors,
        disabled,
    } = props;

    const classes = useStyles();
    const hasError = Boolean(error);

    return (
        <>
            {label && (
                <InputLabel className={classes.margin} htmlFor={`${name}id`}>
                    {label}
                    {info && <InfoBalloon info={info} placement={'right'} />}
                </InputLabel>

            )}
            <FormControl fullWidth={true}>
                <Controller
                    render={({ onChange, value }) => {
                        return (
                            <TextField
                                variant={disabled ? 'standard' : 'filled'}
                                onChange={(e) =>
                                    onChange(parseToCentString(e.target.value))
                                }
                                onFocus={(e) => {
                                    e.target.select();
                                    if (clearErrors) {
                                        clearErrors();
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (disabled) {
                                        e.preventDefault();
                                    }
                                }}
                                value={parseCentToString(value)}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                    name,
                                    disableUnderline: disabled,
                                    inputProps: {
                                        id: `${name}id`
                                    },
                                }}
                                error={hasError}
                                helperText={
                                    hasError ? error.message : undefined
                                }
                                fullWidth
                            />
                        );
                    }}
                    name={name}
                    control={control}
                    rules={validation}
                    defaultValue={'0'}
                />
            </FormControl>
        </>
    );
};

RHFMoneyInput.propTypes = {
    control: PropTypes.object,
    error: PropTypes.object,
    validation: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    info: PropTypes.string,
    clearErrors: PropTypes.func,
    disabled: PropTypes.bool,
};

RHFMoneyInput.defaultProps = {
    clearErrors: null,
    disabled: false,
};

export default RHFMoneyInput;
