import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        '& p': {
            marginBottom: theme.spacing(3),
        },
        '& p:last-child': {
            margin: '0',
        },
    },
}));

const DashboardAnon = (props) => {
    const { lines } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            {lines.map((line, index) => (
                <Typography
                    key={`dash_front_${index}`}
                    component= {line.component ? line.component : "p"}
                    variant={line.variant}
                    gutterBottom
                    dangerouslySetInnerHTML={{ __html: line.text }}
                ></Typography>
            ))}
        </div>
    );
};

DashboardAnon.propTypes = {
    lines: PropTypes.array.isRequired,
};

export default DashboardAnon;
