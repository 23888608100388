import React from 'react';
import Container from '@material-ui/core/Container';
import { useAuth } from '../../../stores/Auth.store';
import { useUserBarStyles } from '../../../styles/components/UserBar/UserBarStyles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BusinessIcon from '@material-ui/icons/Business';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { texts } from '../../../texts/GeneralTexts';
import { useApplicationRounds } from '../../../stores/ApplicationRounds.store';

const UserBar = () => {
    const user = useAuth((state) => state.user);
    const classes = useUserBarStyles();
    const { currentApplicationRound } = useApplicationRounds((state) => ({
        currentApplicationRound: state.currentApplicationRound,
        applicationRoundsById: state.applicationRoundsById,
    }));
    if (!user) return null;

    return (
        <div className={classes.container}  role="complementary"  aria-label={'User info'}>
            <Container>
                <div className={classes.wrapper}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <span
                            style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
                        >
                            <BusinessIcon className={classes.icon} />
                            <span style={{ height: '24px' }}>
                                {user.company?.name}
                            </span>
                        </span>
                        <span
                            style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
                        >
                            <AccountBoxIcon
                                className={classNames(classes.icon, classes.ml)}
                            />
                            <span style={{ height: '24px' }}>
                                {user.fullName}
                            </span>
                        </span>
                        <span
                            style={{ marginRight: '1em', whiteSpace: 'nowrap' }}
                        >
                            <MailOutlineIcon
                                className={classNames(classes.icon, classes.ml)}
                            />
                            <span style={{ height: '24px' }}>{user.email}</span>
                        </span>
                        <Tooltip
                            title={<Typography>{texts.logout}</Typography>}
                        >
                            <Link
                                aria-label={'Abmelden'}
                                component={RouterLink}
                                to={'/abmelden'}
                            >
                                <ExitToAppIcon
                                    aria-label={'Abmelden'}
                                    className={classNames(
                                        classes.icon,
                                        classes.ml
                                    )}
                                />
                            </Link>
                        </Tooltip>
                    </div>
                    <div style={{ minHeight: '24px' }}>
                        {texts.round(currentApplicationRound)}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default UserBar;
