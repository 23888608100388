import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { ListItem, ListItemText } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles({
    alphaList: {
        counterReset: 'list',
        listStyle: 'none',
        '& li': {
            '&::before': {
                counterIncrement: 'list',
                content: 'counter(list, lower-alpha) ") "' ,
            },
        }
        } ,
    numberList: {
        counterReset: 'list',
        listStyle: 'none',
        '& li': {
            '&::before': {
                counterIncrement: 'list',
                content: '"(" counter(list, decimal) ") "' ,
            },
        }
    } ,

});

const DataPrivacy = () => {

    const classes = useStyles();
    return (
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Datenschutzerklärung
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Über das AusbildungWeltweit Projektportal werden webbasierte
                    Formulare zur Antragstellung und zur Umsetzung bewilligter
                    Vorhaben bereitgestellt. Die nachfolgende Erklärung gibt
                    Ihnen einen vollständigen Überblick, welche Art von
                    (personenbezogenen) Daten zu welchem Zweck erhoben werden
                    und wie der Schutz der Daten gewährleistet wird.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Mit der Umsetzung der Fördermaßnahme hat das
                    Bundesministerium für Bildung und Forschung (BMBF)
                    beauftragt:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Nationale Agentur Bildung für Europa beim Bundesinstitut für
                    Berufsbildung (NA beim BIBB)
                </Typography>
                <Typography variant="body1">
                    Friedrich-Ebert-Allee 114 – 116
                </Typography>
                <Typography variant="body1">53113 Bonn</Typography>
                <Typography variant="body1" gutterBottom>
                    Telefon: 0228 107 1611
                </Typography>
                <Typography variant="body1">
                    E-Mail:{' '}
                    <a href="mailto:ausbildung-weltweit@bibb.de">
                        ausbildung-weltweit@bibb.de
                    </a>
                </Typography>
                <Typography variant="body1">
                    www.ausbildung-weltweit.de
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die technische Umsetzung des Projektportals erfolgt durch:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    DLR-Projektträger
                </Typography>
                <Typography variant="body1">Heinrich-Konen-Str. 1</Typography>
                <Typography variant="body1">53227 Bonn</Typography>
                <Typography variant="body1" gutterBottom>
                    Als Empfänger der Formulare verarbeiten wir personenbezogene
                    Daten nur im notwendigen Umfang und folgen somit dem
                    Grundsatz der Datensparsamkeit.
                </Typography>
                <Typography variant="h6" component="h2">
                    I. Name und Anschrift des Verantwortlichen
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Der Verantwortliche im Sinne der Datenschutz-Grundverordnung
                    und des Bundesdatenschutzgesetzes sowie sonstiger
                    datenschutzrechtlicher Bestimmungen ist:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Bundesinstitut für Berufsbildung (BIBB)
                </Typography>
                <Typography variant="body1">
                    Friedrich-Ebert-Allee 114 – 116
                </Typography>
                <Typography variant="body1">53113 Bonn</Typography>
                <Typography variant="body1" gutterBottom>
                    Gesetzlicher Vertreter des BIBB ist der Präsident, Prof. Dr.
                    Friedrich Hubert Esser
                </Typography>
                <Typography variant="h6" component="h2">
                    II. Name und Anschrift des Datenschutzbeauftragten
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Der Datenschutzbeauftragte des Verantwortlichen ist:
                </Typography>
                <Typography variant="body1">Bernd Fahr</Typography>
                <Typography variant="body1" gutterBottom>
                    Friedrich-Ebert-Allee 114 – 116
                </Typography>
                <Typography variant="body1">53113 Bonn</Typography>
                <Typography variant="body1">Telefon: 0228 107-1713</Typography>
                <Typography variant="body1">
                    E-Mail:{' '}
                    <a href="mailto:datenschutzbeauftragter(at)bibb.de">
                        datenschutzbeauftragter(at)bibb.de
                    </a>
                </Typography>
                <Typography variant="h6" component="h2">
                    III. Allgemeines zur Datenverarbeitung
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wir verarbeiten personenbezogene Daten unserer Nutzer/innen
                    grundsätzlich nur, soweit dies zur Bereitstellung einer
                    funktionsfähigen Website sowie unserer Inhalte und
                    Leistungen erforderlich ist. Die Verarbeitung
                    personenbezogener Daten unserer Nutzer/innen erfolgt auf
                    Grundlage einer Einwilligung des Nutzers.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die personenbezogenen Daten der betroffenen Person werden
                    gelöscht oder gesperrt, sobald der Zweck der Speicherung
                    entfällt. Eine Speicherung kann darüber hinaus erfolgen,
                    wenn dies durch den europäischen oder nationalen Gesetzgeber
                    in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen
                    Vorschriften, denen der Verantwortliche unterliegt,
                    vorgesehen wurde. Eine Sperrung oder Löschung der Daten
                    erfolgt auch dann, wenn eine durch die genannten Normen
                    vorgeschriebene Speicherfrist abläuft, es sei denn, dass
                    eine Erforderlichkeit zur weiteren Speicherung der Daten für
                    einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                </Typography>
                <Typography variant="body1">
                    Für jede Verarbeitung von personenbezogenen Daten auf
                    unseren Internetseiten finden Sie in dieser
                    Datenschutzerklärung unter dem jeweiligen Stichwort
                    Erläuterungen zu folgenden Aspekten:
                </Typography>
                <Typography variant="body1" component="ol">
                    <ol className={classes.alphaList}>
                        <li>Umfang der Verarbeitung personenbezogener Daten</li>
                        <li>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</li>
                        <li>Zweck der Datenverarbeitung</li>
                        <li>Dauer der Speicherung</li>
                        <li>Widerspruchs- und Beseitigungsmöglichkeit</li>
                    </ol>
                </Typography>
                <Typography variant="h6" component="h2">
                    IV. Bereitstellung der Website und Erstellung von Logfiles
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>a. Beschreibung und Umfang der Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1">
                    Diese Website erhebt und speichert automatisch in den
                    Server-Logfiles Informationen, die Ihr Browser übermittelt.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Folgende Daten werden hierbei erhoben:
                </Typography>
                <Typography variant="body1" component="ol">
                    <ol className={classes.numberList}>
                        <li>Informationen über den Browsertyp und die verwendete Version</li>
                        <li>Das Betriebssystem des Nutzers</li>
                        <li>Die IP-Adresse des Nutzers</li>
                        <li>Datum und Uhrzeit des Zugriffs</li>
                        <li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt</li>
                        <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
                    </ol>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Eine Speicherung dieser Daten zusammen mit anderen
                    personenbezogenen Daten des Nutzers findet nicht statt.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>b. Rechtsgrundlage für die Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wir sind auf Grundlage des Artikel 6 Absatz 1 lit. c und e
                    EU-Datenschutz-Grundverordnung (DSGVO) in Verbindung mit § 5
                    BSI-Gesetz zur Speicherung der Daten – zum Schutz vor
                    Angriffen auf die Internetinfrastruktur sowie zum Erkennen,
                    Eingrenzen oder Beseitigen von Störungen oder Fehlern bei
                    der Kommunikationstechnik des Bundes/BIBB - über den
                    Zeitpunkt Ihres Besuches hinaus verpflichtet.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Diese Daten werden analysiert und im Falle von Angriffen auf
                    die Kommunikationstechnik zur Einleitung einer Rechts- und
                    Strafverfolgung benötigt.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>c. Zweck der Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die Speicherung in Logfiles erfolgt, um die
                    Funktionsfähigkeit der Website sicherzustellen. Zudem dienen
                    uns die Daten zur Optimierung der Website und zur
                    Sicherstellung der Sicherheit unserer
                    informationstechnischen Systeme. Eine Auswertung der Daten
                    zu Marketingzwecken findet in diesem Zusammenhang nicht
                    statt.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    In diesen Zwecken liegt auch unser berechtigtes Interesse an
                    der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>d. Dauer der Speicherung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die Daten werden gelöscht, sobald sie für die Erreichung des
                    Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im
                    Falle der Speicherung der Daten in Logfiles ist dies nach
                    spätestens sieben Tagen der Fall. Eine darüberhinausgehende
                    Speicherung ist möglich. In diesem Fall werden die
                    IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine
                    Zuordnung des aufrufenden Clients nicht mehr möglich ist.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>e. Widerspruchs- und Beseitigungsmöglichkeit</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die Erfassung der Daten zur Bereitstellung der Website und
                    die Speicherung der Daten in Logfiles ist für den Betrieb
                    der Internetseite zwingend erforderlich. Es besteht folglich
                    seitens des Nutzers keine Widerspruchsmöglichkeit.
                </Typography>
                <Typography variant="h6" component="h2">
                    V. Verwendung von Cookies
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>a. Beschreibung und Umfang der Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Unsere Website verwendet Session-Cookies. Bei Cookies
                    handelt es sich um Textdateien, die im Internetbrowser bzw.
                    vom Internetbrowser auf dem Computersystem des Nutzers
                    gespeichert werden. Ruft ein Nutzer eine Website auf, so
                    kann ein Cookie auf dem Betriebssystem des Nutzers
                    gespeichert werden. Dieser Cookie enthält eine
                    charakteristische Zeichenfolge, die eine eindeutige
                    Identifizierung des Browsers beim erneuten Aufrufen der
                    Website ermöglicht.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Wir setzen ausschließlich technisch notwendige Cookies ein.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>b. Rechtsgrundlage für die Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Bei der Verwendung von technisch notwendigen Cookies ist
                    keine Einwilligung erforderlich.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>c. Zweck der Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Der Zweck der Verwendung technisch notwendiger Cookies ist,
                    die Nutzung von Websites für die Nutzer zu vereinfachen.
                    Einige Funktionen unserer Internetseite können ohne den
                    Einsatz von Cookies nicht angeboten werden. Für diese ist es
                    erforderlich, dass der Browser auch nach einem Seitenwechsel
                    wiedererkannt wird.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die durch technisch notwendige Cookies erhobenen Nutzerdaten
                    werden nicht zur Erstellung von Nutzerprofilen verwendet.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>
                        d. Dauer der Speicherung, Widerspruchs- und
                        Beseitigungsmöglichkeit
                    </b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Cookies werden auf dem Rechner des Nutzers gespeichert und
                    von diesem an unsere Seite übermittelt. Daher haben Sie als
                    Nutzer auch die volle Kontrolle über die Verwendung von
                    Cookies. Durch eine Änderung der Einstellungen in Ihrem
                    Internetbrowser können Sie die Übertragung von Cookies
                    deaktivieren oder einschränken. Bereits gespeicherte Cookies
                    können jederzeit gelöscht werden. Dies kann auch
                    automatisiert erfolgen. Werden Cookies für unsere Website
                    deaktiviert, können möglicherweise nicht mehr alle
                    Funktionen der Website vollumfänglich genutzt werden.
                </Typography>
                <Typography variant="h6" component="h2">
                    VI. Registrierung, Antragstellung und Vorhabenumsetzung im
                    Projektportal
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>a. Beschreibung und Umfang der Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Bei der Nutzung des Projektportals werden im Zuge der
                    Registrierung, Antragstellung und Umsetzung bewilligter
                    Vorhaben folgende Daten über Eingabemasken erhoben:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <b>
                        Registrierung der Einrichtung und der
                        zugangsberechtigten Person („Zugangsmanager“):
                    </b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Anrede, Titel, Vorname, Name, E-Mail-Adresse, Telefonnummer
                    sowie erste Angaben zur Einrichtung (rechtsverbindlicher
                    Name der Einrichtung, Anschrift mit PLZ und Ort, Bundesland)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Zum Zeitpunkt der Registrierung werden zudem Datum und
                    Uhrzeit gespeichert.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Im Rahmen des Registrierungsprozesses wird eine Einwilligung
                    der Nutzerin oder des Nutzers zur Verarbeitung dieser Daten
                    eingeholt.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <b>
                        Zeichnungsberechtigte Person und Vertretung der
                        zeichnungsberechtigten Person bei Stammdaten der
                        Einrichtung:
                    </b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Anrede, Titel, Vorname, Name, Funktion, Abteilung,
                    E-Mail-Adresse, Telefonnummer sowie dienstliche Anschrift
                    mit PLZ und Ort
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <b>
                        Kontaktperson (fachliche Ansprechperson für einen Antrag
                        mit Zugangsberechtigung):
                    </b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Anrede, Titel, Vorname, Name, Funktion, Abteilung,
                    E-Mail-Adresse, Telefonnummer sowie dienstliche Anschrift
                    mit PLZ und Ort
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <b>
                        Daten zu Teilnehmenden und ihren Auslandsaufenthalten:
                    </b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Vorname, Name, Geschlecht (m/w/d), Alter, E-Mail-Adresse,
                    Ausbildungsgang (Auszubildende) oder Ausbildungsbereich
                    (Ausbildende, Vorbereitende Besuche) sowie Angaben zum
                    Aufenthalt (Zielland und Region im Zielland,
                    Partnereinrichtung im Zielland, Aufenthaltsdaten) und dem
                    daraus resultierenden möglichen Zuschuss.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Teilnehmende verfassen einen Bericht nach einer vorgegebenen
                    Struktur.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Eine Auswertung von Teilnehmendendaten zu statistischen
                    Zwecken oder im Zuge einer Evaluierung des Förderprogramms
                    erfolgt nur in anonymisierter Form.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>b. Rechtsgrundlage für die Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Rechtsgrundlage für die Verarbeitung der Daten ist bei
                    Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit.
                    a DSGVO (Registrierung).
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Im Rahmen der Antragstellung und Vorhabenumsetzung müssen
                    personenbezogenen Daten bereitgestellt werden, die für die
                    Umsetzung des Zuwendungsverhältnisses und die Erfüllung der
                    damit verbundenen Pflichten erforderlich sind. Diese
                    Datenfelder werden bei der Datenerhebung als Pflichtfelder
                    gekennzeichnet. Die Nutzung eines elektronischen
                    Antragssystems ist laut Richtlinie des BMBF zum
                    Förderprogramm AusbildungWeltweit vom 28.11.2019
                    verpflichtend. Rechtsgrundlage für die Verarbeitung dieser
                    Daten ist Art. 6 Abs.1 lit. e DSGVO. Die Verarbeitung ist
                    für die Wahrnehmung einer Aufgabe im öffentlichen Interesse
                    erforderlich.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>c. Zweck der Datenverarbeitung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Die Registrierung einer Einrichtung mit einer
                    zugangsberechtigten Person (Nutzer, Nutzerin) ist für das
                    Bereithalten bestimmter Inhalte und Leistungen erforderlich,
                    insbesondere für die Nutzung des elektronischen
                    Formularsystems zur Antragstellung und Umsetzung bewilligter
                    Vorhaben. Anhand des Nutzer-Accounts werden die Anträge und
                    Verwendungsnachweise der Nutzer miteinander verknüpft.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Nutzerinnen und Nutzer stellen über die Webanwendung die
                    Informationen bereit, die für die ordnungsgemäße Prüfung von
                    Förderanträgen und Verwendungsnachweisen benötigt werden.
                    Die in den Formularen enthaltenen personenbezogenen Daten
                    und sonstigen Angaben werden vom Empfänger des Formulars und
                    seinen Beauftragten ausschließlich im Rahmen ihrer
                    Zuständigkeiten erhoben, verarbeitet und genutzt. Die
                    Möglichkeit zur Weitergabe dieser Daten an andere Stellen
                    richtet sich nach der EU-Datenschutz-Grundverordnung
                    (EU-DSGVO) und dem Bundesdatenschutzgesetz (BDSG). Die
                    Möglichkeit zur Weitergabe beinhaltet auch die erforderliche
                    Verarbeitung der erhobenen Daten zum Zweck der
                    Rechnungsprüfung. Soweit in den Formularen personenbezogene
                    Daten von Beschäftigten der antragstellenden Einrichtung,
                    Teilnehmenden oder sonstigen natürlichen Personen enthalten
                    sind, sind diese entsprechend den Datenschutzhinweisen zu
                    informieren und deren Einverständnis einzuholen.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Alle Daten und Informationen aus Anträgen und
                    Verwendungsnachweisen können dem BMBF und den von ihnen
                    beauftragten Prüforganen zugänglich gemacht werden, die
                    bezüglich der Vertraulichkeit denselben Vorschriften
                    unterliegen.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>d. Dauer der Speicherung</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Registrierungsdaten werden auf unserem Server gespeichert
                    und können jederzeit gelöscht werden, solange noch keine
                    Antragstellung erfolgt ist (s. VI e). Werden die
                    Registrierungsdaten nicht innerhalb von zwei Jahren für eine
                    Antragstellung verwendet, werden die Daten automatisch
                    gelöscht.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Im Fall einer Antragstellung werden alle Daten, die für die
                    Umsetzung des Zuwendungsverhältnisses und die Erfüllung der
                    damit verbundenen Pflichten erforderlich sind, gelöscht,
                    sobald sie für die Erreichung des Zweckes ihrer Erhebung
                    nicht mehr erforderlich sind. Die für Zuwendungsgeber
                    vorgeschriebene Aufbewahrungsfrist beträgt derzeit 10 Jahre
                    nach Abschluss der Fördermaßnahme. Der Personenbezug wird
                    danach aufgehoben.
                </Typography>
                <Typography variant="body1"  component="h3" gutterBottom>
                    <b>e. Widerspruchs- und Beseitigungsmöglichkeit</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Als Nutzerin oder Nutzer haben Sie die Möglichkeit, die
                    Registrierung aufzulösen, solange noch kein Antrag
                    eingereicht wurde.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Nach der verbindlichen digitalen Einreichung von Unterlagen
                    ist eine vorzeitige Löschung der Registrierungsdaten nicht
                    mehr möglich. Diese Daten werden in diesem Fall
                    anonymisiert. Die über Sie gespeicherten Daten können Sie
                    jederzeit abändern lassen.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Für eine Löschung oder Änderung des Accounts setzen Sie sich
                    mit der NA beim BIBB per E-Mail unter der Adresse
                    ausbildung-weltweit@bibb.de in Verbindung.
                </Typography>
                <Typography variant="h6" component="h2">
                    VII. Rechte der betroffenen Person
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Sie haben uns gegenüber für die Verarbeitung Ihrer
                    personenbezogenen Daten folgende Rechte gemäß DSGVO:
                </Typography>
                <List>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText primary="Recht auf Auskunft über Ihre personenbezogenen Daten (Art. 15 DSGVO)" />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText primary="Recht auf Berichtigung, Sperrung oder Löschung (Art. 16 u. 17 DSGVO)" />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText primary="Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)" />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText primary="Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO) sowie das" />
                    </ListItem>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemText primary="Recht auf Datenübertragbarkeit (Art. 20 DSGVO)." />
                    </ListItem>
                </List>
                <Typography variant="body1" gutterBottom>
                    Um sicherzustellen, dass bei Wahrnehmung Ihrer Rechte
                    personenbezogene Daten nicht an Dritte herausgegeben oder
                    unter Angabe einer falschen Identität gelöscht werden,
                    richten Sie Ihre Anfrage bitte per E-Mail oder per Post
                    unter eindeutiger Identifizierung Ihrer Person (zum Beispiel
                    durch eine Ausweiskopie) an die verantwortliche Stelle.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Zusätzlich zu den oben genannten Rechten haben Sie das
                    Recht, eine Beschwerde bei einer Datenschutzaufsichtsbehörde
                    einzureichen (Art. 13 Abs. 2 DSGVO).
                </Typography>
            </Grid>
        </Grid>
    );
};

DataPrivacy.propTypes = {};

export default DataPrivacy;
