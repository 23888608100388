import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { texts } from '../../../texts/GeneralTexts';
import Typography from '@material-ui/core/Typography';

const DialogWithConfirmation = (props) => {
    const {
        open,
        closeFunc,
        confirmFunc,
        content,
        title,
        cancelButtonText,
        confirmButtonText,
        formId,
        maxWidth,
    } = props;

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (open) {
            setDisabled(false);
        }
    }, [open])

    return (
        <Dialog
            role="main"
            open={open}
            PaperProps={{
                'aria-label': title,
            }}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-content"
            maxWidth={maxWidth}
        >
            <DialogTitle id="dialog-title">
                <Typography variant="h5" component="h1">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent id="dialog-content">{content}</DialogContent>
            <DialogActions>
                <Button onClick={() => closeFunc(false)} color="primary">
                    {cancelButtonText ? cancelButtonText : texts.cancel}
                </Button>
                <Button
                    type={formId ? 'submit' : 'button'}
                    form={formId ? formId : undefined}
                    onClick={() => {
                        closeFunc(true);
                        if (!formId) {
                            setDisabled(true);
                            confirmFunc();
                        }
                    }}
                    color="primary"
                    disabled={disabled}
                    autoFocus
                >
                    {confirmButtonText ? confirmButtonText : texts.send}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogWithConfirmation.propTypes = {
    closeFunc: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    confirmFunc: PropTypes.func,
    formId: PropTypes.string,
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    maxWidth: PropTypes.string,
};

export default DialogWithConfirmation;
