import {api, authApi} from '../AxiosConfig/Config';
import {loginCookieExists} from '../../../util/Helper/CookieFunctions';

export function fetchUserData() {
    if (!loginCookieExists()) {
        return Promise.reject();
    }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await api().get('/action/account-profile');
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function fetchToken({verify, code}) {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.set('grant_type', 'authorization_code');
            formData.set('client_id', process.env.REACT_APP_CLIENT_ID);
            formData.set('code', code);
            formData.set('code_verifier', verify);
            formData.set('scope', 'user');
            formData.set(
                'redirect_uri',
                `${process.env.REACT_APP_HOST}${process.env.REACT_APP_REDIRECT}`
            );

            const response = await authApi.post('/token', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function removeToken() {
    return new Promise(async (resolve, reject) => {
        try {
            const response = authApi.get('/logout');
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export function refreshToken() {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.set('grant_type', 'refresh_token');
            formData.set('client_id', process.env.REACT_APP_CLIENT_ID);

            const response = await authApi.post('/token', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            resolve(response);
        } catch (e) {
            reject(e);
        }
    });
}
