import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import { registerFormTexts as rft } from '../../../texts/Forms/RegisterFormTexts';
import { registerFormConfig as config } from '../../../config/Forms/RegisterFormConfig';
import Grid from '@material-ui/core/Grid';
import PasswordAdornment from './PasswordAdornment';
import Complete from '../../Partials/Form/Autocomplete';
import { getStateList } from '../../../util/constants/States';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import RegisterSuccess from './RegisterSuccess';
import RegisterError from './RegisterError';
import RHFTextField from '../../Partials/Form/RHFTextField';
import _ from 'lodash';
import GenericTextField from '../../Partials/Form/GenericTextField';
import GenericSelect from '../../Partials/Form/GenericSelect';
import CheckboxWithInfoText from '../../Partials/Form/CheckboxWithInfoText';
import TextBlock from '../../TextBlock/TextBlock';
import PhoneAdornment from './PhoneAdornment';
import { Alert } from '@material-ui/lab';

const RegisterForm = (props) => {
    const { error, isLoading, register: callRegister } = props;

    const [registerSuccess, setRegisterSuccess] = React.useState(undefined);

    const {
        errors,
        handleSubmit,
        control,
        watch,
        getValues,
        setError,
        register,
        clearErrors,
    } = useForm();

    const password = watch(config.password.name);
    const phone = watch(config.phone.name);

    const submitCallback = (values) => {
        callRegister(values)
            .then((response) => {
                setRegisterSuccess(response);
            })
            .catch(() => {});
    };

    if (Boolean(registerSuccess)) return <RegisterSuccess />;

    return (
        <div>
            <Typography variant="h4" component="h1">
                {rft.title}
            </Typography>
            <Alert style={{ margin: '20px 0' }} severity="info">
                {rft.info}
            </Alert>
            <RegisterError error={error} setError={setError} />
            <form onSubmit={handleSubmit(submitCallback)} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.email}
                            control={control}
                            errors={errors}
                            autoFocus
                            inputProps={{ 'data-testid': 'email' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            label={config.emailConfirm.label}
                            control={control}
                            name={config.emailConfirm.name}
                            error={_.get(errors, config.emailConfirm.name)}
                            validation={config.emailConfirm.validation(
                                getValues
                            )}
                            inputProps={{ 'data-testid': 'email_confirm' }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            label={config.password.label}
                            control={control}
                            name={config.password.name}
                            error={_.get(errors, config.password.name)}
                            validation={config.password.validation}
                            type="password"
                            InputProps={{
                                endAdornment: (
                                    <PasswordAdornment password={password} />
                                ),
                            }}
                            inputProps={{ 'data-testid': 'password' }}
                            helperText="Das Passwort muss enthalten: mind. 10 Zeichen und drei der folgenden Merkmale: Großschreibung, Kleinschreibung, mind. ein Sonderzeichen oder mind. eine Ziffer"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RHFTextField
                            label={config.passwordConfirm.label}
                            control={control}
                            name={config.passwordConfirm.name}
                            error={_.get(errors, config.passwordConfirm.name)}
                            validation={config.passwordConfirm.validation(
                                getValues
                            )}
                            type="password"
                            inputProps={{ 'data-testid': 'password_confirm' }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>{rft.personalData}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <GenericSelect
                            config={config.salutation}
                            control={control}
                            errors={errors}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.title}
                            control={control}
                            errors={errors}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.firstName}
                            control={control}
                            errors={errors}
                            inputProps={{ 'data-testid': 'first_name' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.lastName}
                            control={control}
                            errors={errors}
                            inputProps={{ 'data-testid': 'last_name' }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.phone}
                            control={control}
                            errors={errors}
                            InputProps={{
                                endAdornment: <PhoneAdornment phone={phone} />,
                            }}
                            inputProps={{
                                'data-testid': 'phone',
                            }}
                            helperText={config.phone.helperText}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>{rft.companyData}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <GenericTextField
                            config={config.companyName}
                            control={control}
                            errors={errors}
                            inputProps={{ 'data-testid': 'company_name' }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.street}
                            control={control}
                            errors={errors}
                            inputProps={{ 'data-testid': 'street' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.postcode}
                            control={control}
                            errors={errors}
                            inputProps={{ 'data-testid': 'postcode' }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <GenericTextField
                            config={config.city}
                            control={control}
                            errors={errors}
                            inputProps={{ 'data-testid': 'city' }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Complete
                            asyncFunc={getStateList}
                            control={control}
                            name={config.state.name}
                            queryId={'states'}
                            error={errors[config.state.name]}
                            rules={config.state.validation}
                            label={config.state.label}
                            inputProps={{ 'data-testid': 'state' }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" component="h2">
                            {rft.consent.title}
                        </Typography>
                        <TextBlock textBlock={rft.consent.text} />
                        <CheckboxWithInfoText
                            register={register}
                            errors={errors.consent}
                            label={config.consent.label}
                            name="consent"
                            validation={config.consent.validation}
                            inputProps={{ 'data-testid': 'consent' }}
                        />
                    </Grid>

                    <Grid container justify="center">
                        <Grid item xs={4}>
                            <Button
                                id={rft.register}
                                type="submit"
                                disabled={isLoading}
                                color="primary"
                                variant="contained"
                                disableElevation
                                fullWidth
                                data-testid="submit"
                                onClick={() => {
                                    clearErrors();
                                }}
                            >
                                {isLoading ? (
                                    <CircularProgress aria-labelledby={rft.register} size="1rem" />
                                ) : (
                                    <>{rft.register}</>
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

RegisterForm.propTypes = {
    register: PropTypes.func,
    error: PropTypes.object,
    isLoading: PropTypes.bool,
};

export default RegisterForm;
