export const finalizationFormTexts = {
    info:
        'Sie haben die Möglichkeit, Ihre Angaben als Entwurfs-PDF herunterzuladen und zu überprüfen. Klicken Sie ' +
        'dazu auf den Button "Download Antragsentwurf". Sollten Sie noch Änderungen durchführen wollen, können Sie ' +
        'jederzeit durch Klick auf die Pfeile oder die Schrittnummer den gewünschten Bereich erreichen.  Ein Entwurf '+
        'ist keine gültige Antragsfassung.',
    info2:
        'Bitte bestätigen Sie final, dass alle Angaben richtig sind und klicken auf den Befehl "Antrag elektronisch ' +
        'übermitteln". Danach können keine Änderungen im Antrag mehr vorgenommen werden. Drucken Sie dann erst die ' +
        'Papierversion für die Antragseinreichung aus.',
    isVerified: {
        label:
            'Hiermit bestätige ich die  Richtigkeit und Vollständigkeit der Angaben.',
        error:
            'Bitte bestätigen Sie die Richtigkeit und Vollständigkeit der Angaben.',
    },
    confirm: {
        title: 'Bestätigung',
        body:
            'Bitte bestätigen Sie, dass Sie den Antrag unwiderruflich einreichen möchten. Eine nachträgliche ' +
            'Antragsbearbeitung ist dann nicht mehr möglich.',
        info:
            'Die Antragseinreichung erfolgt nun in zweifacher Ausfertigung per Post: Ausdruck des Antrags mit allen ' +
            'Absichtserklärungen (Letter of Intent) der im Antrag genannten Partnereinrichtungen, siehe auch ' +
            '"Checkliste zur Einreichung" am Ende des Ausdrucks.',
        cancel: 'Abbrechen',
        accept: 'Antrag elektronisch übermitteln',
    },
    pdf: 'Download Antragsentwurf',
    changeRequest:
        'Sie können den Antrag nicht abschließend elektronisch übermitteln, da Ihre Stammdatenänderungen durch die NA beim BIBB zur Zeit noch geprüft werden.',
    coreDataNotComplete:
        'Sie können den Antrag nicht abschließend elektronisch übermitteln, da Ihre Stammdaten unvollständig sind. Um die Stammdaten jetzt zu vervollständigen, klicken Sie bitte ',
    changeRequestSupport:
        'Falls Sie Fragen haben, wenden Sie sich an Tel.: 0228 / 107 – 1611 oder E-Mail: ausbildung-weltweit@bibb.de.',
};
